import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, NavLink, Redirect } from 'react-router-dom';
import { validateCaptcha } from 'react-simple-captcha';
import LandingPage from './components/LandingPage';
import ConfirmEmail from './components/ConfirmEmail';
import Account from './components/Account';
import Why from './components/Why';
import Profile from './components/Profile';
import Search from './components/Search';
import axios from 'axios';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';


const App = () => {

  const [isUsername, setUsername] = useState('');
  const [isLoggedUsername, setLoggedUsername] = useState('');
  const [isEmail, setEmail] = useState('');
  const [isPassword, setPassword] = useState('');
  const [isRecruiter, setRecruiter] = useState('');
  const [pickedRecruiterID, setPickedRecruiterID] = useState('');
  const [isCaptchaInput, setCaptchaInput] = useState('');
  const [isComment, setComment] = useState('');
  const [isRegister, setRegister] = useState(false);
  const [profileToDisplay, setProfileToDisplay] = useState({});
  const [isProfileToShow, setProfileToShow] = useState(false);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isCrossPressed, setCrossPressed] = useState(false);
  const [isNoUserFound, setNoUserFound] = useState(false);
  const [isRecruiterResults, setRecruiterResults] = useState({});
  const [isStars, setStars] = useState(0);
  const [isFirst, setFirst] = useState(false);
  const [lookingAtAbout, setLookingAtAbout] = useState(false);
  const [isSecond, setSecond] = useState(false);
  const [isThird, setThird] = useState(false);
  const [isFourth, setFourth] = useState(false);
  const [isShowInvalidMessage, setShowInvalidMessage] = useState(false);
  const [isShowUserExists, setShowUserExists] = useState(false)
  const [isShowSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isShowNotAllowedMessage, setShowNotAllowedMessage] = useState(false);
  const [isValidationTriggered, setValidationTriggered] = useState(false);
  const [isInvalidInputDetected, setInvalidInputDetected] = useState(false);
  const [isEmailConfirmed, setEmailConfirmed] = useState(false);
  const [isShownOnce, setShownOnce] = useState(false);
  const [showInvalidEmail, setInvalidEmail] = useState(false);
  const [showNoResult, setNoResult] = useState(false);
    

  useEffect(() => {
    loginImmediatelly();
  }, []);


  useEffect(() => {
    if(isEmailConfirmed && !isShownOnce) {
      setTimeout(() => {
        setShownOnce(true);
        const json = JSON.stringify({id: localStorage.getItem('recruitdexID')});
        axios.post('https://marekintech.pythonanywhere.com/bot_gateway_two', json, { headers: {'Content-Type': 'application/json'}});
      }, 5000);
      
    }
  
  }, [isEmailConfirmed, !isShownOnce]);


  const loginImmediatelly = async e => {
    if(localStorage.getItem('recruitdexID')) {
      const json = JSON.stringify({id: localStorage.getItem('recruitdexID')});
      const res = await axios.post('https://marekintech.pythonanywhere.com/login', json, { headers: {'Content-Type': 'application/json'}});
      if(res.data.status === 'success') {
        setLoggedUsername(res.data.username);
        setAuthenticated(true);
        if(res.data.confirmed) {
          setEmailConfirmed(true);
        }
        if(res.data.shown_once) {
          setShownOnce(true);
        }
      } else {
        console.log('no account found.');
      }
    }
  }

  const handleLogout = () => {
    localStorage.removeItem("recruitdexID");
    setAuthenticated(false);
  }

  const handleReviewSubmission = async e => {
    e.preventDefault();
    let numStars;
    if(isFirst && isSecond && isThird && isFourth) {
      numStars = 3;
    } else if(isFirst && isSecond && isThird) {
      numStars = 2;
    } else if(isFirst && isSecond) {
      numStars = 1;
    } else {
      numStars = 0;
    }
    //console.log(`comment is ${isComment}`);
    const json = JSON.stringify({user_id: localStorage.getItem('recruitdexID'), comment: isComment, urn_id: pickedRecruiterID, stars: numStars});
    const res = await axios.post('https://marekintech.pythonanywhere.com/add_review', json, { headers: {'Content-Type': 'application/json'}});
    if(res.data.status === 'success') {
      setFirst(false);
      setSecond(false);
      setThird(false);
      setFourth(false);
      setShowSuccessMessage(true);
      handleSearchButton(e);
      setTimeout(() => {
        setShowSuccessMessage(false);
        setProfileToShow(false);
      }, 5000);
      
    } else {
      setShowNotAllowedMessage(true);
      setTimeout(() => {
        setShowNotAllowedMessage(false);
      }, 5000);
    }
  }


  const handleSearchButton = async e => {
    e.preventDefault();
    
    if(isRecruiter === '') {
      console.log('#');
    } else {
      const forbidden_chars = ';,@#[]()*';
      let validated = true;
      for(let i = 0; i < isRecruiter.length; i++) {
        if(isRecruiter.includes(forbidden_chars[i])) {
          validated = false;
      }
      if(validated) {
        const json = JSON.stringify({name: isRecruiter});
        const res = await axios.post('https://marekintech.pythonanywhere.com/get_data', json, { headers: {'Content-Type': 'application/json'}});
        if(res.data.recruiters.length !== 0) {
          setRecruiterResults(res.data.recruiters);
        } else {
          setNoResult(true);
          setTimeout(() => {
            setNoResult(false);
          }, 5000);
        }
      } else {
        setInvalidInputDetected(true);
        setTimeout(() => {
          setInvalidInputDetected(false);
          setRecruiter('');
        }, 5000);
      }
    }
    }
   
  }

  const handleProfileFetch = e => {
    let rec = isRecruiterResults.filter(rec => rec.name.includes(e.target.name));
    setProfileToShow(true);
    setProfileToDisplay(rec[0]);
    console.log(rec[0].comments);
  }

  const handleCrossButton = e => {
    e.preventDefault();
    setUsername('');
    setEmail('');
    setPassword('');
    setCaptchaInput('');
    setCrossPressed(true);
    setProfileToShow(false);
    setTimeout(() => {
      setCrossPressed(false);
    }, 2000);
  }

  const handleSubmit = async e => {
    e.preventDefault();
    if (validateCaptcha(isCaptchaInput) !==true) {
      alert('Captcha Does Not Match');
    } else {
      let firstOK = true;
      let secondOK = true;
      let thirdOK = true;
      let emailOK = false;
      let validationTrigger = false;
      const forbidden_chars = ";,#[']*";
      const specialChars = '@#£$%&*()!?<>~';
      const allowed_emails = ['@gmail', '@yahoo', '@live', '@outlook', '@mail', '@btinternet',
        '@protonmail', '@proton', '@icloud', '@googlemail', '@murena', '@pm'];
      
      const nums = '1234567890';
      if(isRegister) {
        for(let i = 0; i < allowed_emails.length; i++) {
          if(isEmail.includes(allowed_emails[i])) {
            emailOK = true;
            break;
          }
        }
        for(let i = 0; i < isUsername.length; i++) {
          if(isUsername.includes(forbidden_chars[i])) {
            firstOK = false;
          }
        }
        for(let i = 0; i < isPassword.length; i++) {
          if(isPassword.includes(specialChars[i])) {
            validationTrigger = false;
            break;
          } else {
            validationTrigger = true;
          }
        }
        for(let i = 0; i < isPassword.length; i++) {
          if(isPassword.includes(nums[i])) {
            validationTrigger = false;
            break;
          } else {
            validationTrigger = true;
          }
        }
      } 
      for(let i = 0; i < isEmail.length; i++) {
        if(isEmail.includes(forbidden_chars[i])) {
          secondOK = false;
        }
      }
      for(let i = 0; i < isPassword.length; i++) {
        if(isPassword.includes(forbidden_chars[i])) {
          thirdOK = false;
        }
      } 
      
      if(isRegister && isUsername.length < 6 || isPassword.length < 8) {
        validationTrigger = true;
      }
      
      if(validationTrigger) {
        setValidationTriggered(true);
        setTimeout(() => {
          setValidationTriggered(false);
        }, 5000);
      } else {
        if(firstOK && secondOK && thirdOK && emailOK) {
          const json = JSON.stringify({username: isUsername, email: isEmail, password: isPassword, register: isRegister ? 'register': 'login'});
          const res = await axios.post('https://marekintech.pythonanywhere.com/account', json, { headers: {'Content-Type': 'application/json'}});
          if(res.data.status === 'success') {
            setUsername('');
            setEmail('');
            setPassword('');
            setAuthenticated(true);
            
            if(res.data.username.length !== 0) {
              setLoggedUsername(res.data.username);
            }
            localStorage.setItem("recruitdexID", res.data.id);
                        
          } else if(res.data.message === 'no user found') {
            setNoUserFound(true);
            setTimeout(() => {
              setNoUserFound(false);
            }, 5000);
          } else if(res.data.message === 'duplicate') {
            setShowUserExists(true);
            setTimeout(() => {
              setShowUserExists(false);
            }, 5000);
          }
        } else if(!emailOK) {
          setInvalidEmail(true);
          setTimeout(() => {
            setInvalidEmail(false);
          }, 10000);
        } else {
          setShowInvalidMessage(true);
          setTimeout(() => {
            setShowInvalidMessage(false);
          }, 5000);
        }
      }
    }
    
  }

  return (
    <>
    <BrowserRouter>
    
    {isAuthenticated && !lookingAtAbout && <Redirect to="/" replace />}
    {isCrossPressed && <Redirect to="/" replace />}
    {Object.keys(isRecruiterResults).length !== 0 && !isProfileToShow && <Redirect to="/search" replace />}
    {Object.keys(isRecruiterResults).length !== 0 && isProfileToShow && <Redirect to="/profile" replace />}
    
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container-fluid">
            <a className="navbar-brand" href="/" alt="link to landing page">recruitDex</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                {!isAuthenticated && <NavLink style={{marginBottom: '3px'}} onClick={() => {setRecruiterResults({})}} className="btn btn-outline-secondary" alt="link to Register or to Log in" to="/account" exact>Account</NavLink>}
                </li>
                {isAuthenticated && <li style={{marginBottom: '3px'}} className='btn btn-dark'>{isLoggedUsername}</li>}
                {isAuthenticated && (
                  <>
                  <li style={{marginLeft: '30px', marginTop: '10px'}} className='nav-item'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star" viewBox="0 0 16 16">
                <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z"/>
                </svg>&nbsp;&nbsp;&nbsp;Ghosts Candidates</li>
                <li style={{marginLeft: '30px', marginTop: '10px'}} className='nav-item'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg>&nbsp;&nbsp;&nbsp;Replies Within 2 Weeks (no copy-cat)</li>
                <li style={{marginLeft: '30px', marginTop: '10px'}} className='nav-item'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg>&nbsp;&nbsp;&nbsp;Provides Helpful Feedback</li>
                <li style={{marginLeft: '30px', marginTop: '10px'}} className='nav-item'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg>&nbsp;&nbsp;&nbsp;Keeps Regular Contact</li>
                  </>
                )}
                {!isAuthenticated && (
                  <>
                  <li style={{marginLeft: '30px', marginTop: '13px'}} className='nav-item'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star" viewBox="0 0 16 16">
                <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z"/>
                </svg>&nbsp;&nbsp;&nbsp;Ghosts Candidates</li>
                <li style={{marginLeft: '30px', marginTop: '13px'}} className='nav-item'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg>&nbsp;&nbsp;&nbsp;Replies Within 2 Weeks (no copy-cat)</li>
                <li style={{marginLeft: '30px', marginTop: '13px'}} className='nav-item'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg>&nbsp;&nbsp;&nbsp;Provides Helpful Feedback</li>
                <li style={{marginLeft: '30px', marginTop: '13px'}} className='nav-item'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                </svg>&nbsp;&nbsp;&nbsp;Keeps Regular Contact</li>
                  </>
                )}
                
              </ul>
              {window.innerWidth > 800 && Object.keys(isRecruiterResults).length !== 0 && (
              <form className="d-flex" role="search">
                <input className="form-control me-2" type="search" onChange={e => setRecruiter(e.target.value)}
                 placeholder="Search Recruiters" aria-label="Search" />
                <button className="btn btn-outline-success" onClick={(e) => handleSearchButton(e)} type="submit">Search</button>
              </form>
              )}
              {isAuthenticated && <button style={{marginLeft: '20px'}} className='btn btn-secondary' onClick={() => handleLogout()}>Logout</button>}
              {!isAuthenticated && <NavLink style={{marginLeft: '20px'}} className='btn btn-secondary' alt="link to Why recruitDex page" to='/why'>Why recruitDex</NavLink>}
            </div>
          </div>
        </nav>
               
        <Route exact path='/'><LandingPage 
                               isInvalidInputDetected={isInvalidInputDetected}
                               isRecruiter={isRecruiter}
                               setRecruiter={setRecruiter}
                               handleSearchButton={handleSearchButton}
                               isLoggedUsername={isLoggedUsername}
                               isEmailConfirmed={isEmailConfirmed}
                               isShownOnce={isShownOnce}
                               showNoResult={showNoResult}
                  />
        </Route>
        <Route exact path='/why'><Why handleCrossButton={handleCrossButton} /></Route>
        <Route exact path='/confirm_email/:code'><ConfirmEmail
                               setEmailConfirmed={setEmailConfirmed}
                               
                  />
        </Route>
        <Route exact path='/search'><Search
                               handleProfileFetch={handleProfileFetch}
                               isRecruiterResults={isRecruiterResults}
                               setRecruiter={setRecruiter}
                               isRecruiterResults={isRecruiterResults}
                               handleSearchButton={handleSearchButton}
                   />
        </Route>
        <Route exact path='/profile'><Profile 
                               isShowInvalidMessage={isShowInvalidMessage}
                               isShowSuccessMessage={isShowSuccessMessage}
                               isShowNotAllowedMessage={isShowNotAllowedMessage}
                               setStars={setStars}
                               profileToDisplay={profileToDisplay}
                               isAuthenticated={isAuthenticated}
                               handleCrossButton={handleCrossButton}
                               handleReviewSubmission={handleReviewSubmission}
                               setPickedRecruiterID={setPickedRecruiterID}
                               setComment={setComment}
                               isFirst={isFirst}
                               isSecond={isSecond}
                               isThird={isThird}
                               isFourth={isFourth}
                               setFirst={setFirst}
                               setSecond={setSecond}
                               setThird={setThird}
                               setFourth={setFourth}                              
                 />
        </Route>
        <Route exact path='/account'><Account
                               handleCrossButton={handleCrossButton}
                               handleSubmit={handleSubmit}
                               isNoUserFound={isNoUserFound}
                               isRegister={isRegister}
                               setRegister={setRegister}
                               isUsername={isUsername}
                               setUsername={setUsername}
                               setPassword={setPassword}
                               isPassword={isPassword}
                               isCaptchaInput={isCaptchaInput}
                               setCaptchaInput={setCaptchaInput}
                               isValidationTriggered={isValidationTriggered}
                               isShowUserExists={isShowUserExists}
                               setEmail={setEmail}
                               isEmail={isEmail}
                               showInvalidEmail={showInvalidEmail}
               />
        </Route>
        </BrowserRouter>
    </>
  );
}

export default App;
