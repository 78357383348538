import React, { useEffect } from 'react';


const Profile = props => {

    const { isFirst, setFirst, isSecond, setSecond,
        isAuthenticated, isThird, setThird, isFourth, setFourth,
         handleCrossButton, handleReviewSubmission,
        setPickedRecruiterID, profileToDisplay,
        isShowSuccessMessage, isShowInvalidMessage,
        isShowNotAllowedMessage, setComment } = props;

    useEffect(() => {
        setPickedRecruiterID(profileToDisplay.urn_id);
    }, []);


    useEffect(() => {
        if(isFourth) {
            setFirst(true);
            setSecond(true);
            setThird(true);
        } else if(isThird) {
            setFirst(true);
            setSecond(true);
            setFourth(false);
        } else if(isSecond) {
            setFirst(true);
            setThird(false);
            setFourth(false);
        } 
        
    }, [isFirst, isSecond, isThird, isFourth]);

        
    return (
        <>
        <div className='container' style={{marginTop: '100px'}}>
        <button style={{borderColor: 'transparent', position: 'absolute', right: window.innerWidth < 800 ? '7px': '100px',
         top: window.innerWidth < 800 ? '60px': '100px', backgroundColor: 'transparent'}}
         onClick={e => handleCrossButton(e)}><svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
          className="bi bi-x" viewBox="0 0 16 16">
        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
        </svg></button>
        <h1 className='fs-1'>Rate your experience with {profileToDisplay.name}:</h1>
        <br/>
        {!isFirst && <svg onClick={() => setFirst(!isFirst)} xmlns="http://www.w3.org/2000/svg" width="32" name="first" height="32" fill="currentColor" className="bi bi-star" viewBox="0 0 16 16">
        <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z"/>
        </svg>}
        {isFirst && <svg onClick={() => setFirst(!isFirst)} xmlns="http://www.w3.org/2000/svg" width="32" name="second" height="32" fill="#0099ff" className="bi bi-star" viewBox="0 0 16 16">
        <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z"/>
        </svg>}
        &nbsp;&nbsp;&nbsp;

        {!isSecond && <svg onClick={() => setSecond(!isSecond)} xmlns="http://www.w3.org/2000/svg" width="32" name="third" height="32" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
        </svg>}
        {isSecond && <svg onClick={() => setSecond(!isSecond)} xmlns="http://www.w3.org/2000/svg" width="32" height="32" name="fourth" fill="#0099ff" className="bi bi-star-fill" viewBox="0 0 16 16">
        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
        </svg>}
        &nbsp;&nbsp;&nbsp;
        {!isThird && <svg onClick={() => setThird(!isThird)} xmlns="http://www.w3.org/2000/svg" width="32" height="32" name="fifth" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
        </svg>}
        {isThird && <svg onClick={() => setThird(!isThird)} xmlns="http://www.w3.org/2000/svg" width="32" height="32" name="sixth" fill="#0099ff" className="bi bi-star-fill" viewBox="0 0 16 16">
        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
        </svg>}
        &nbsp;&nbsp;&nbsp;
        {!isFourth && <svg onClick={() => setFourth(!isFourth)} xmlns="http://www.w3.org/2000/svg" width="32" height="32" name="seventh" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
        </svg>}
        {isFourth && <svg onClick={() => setFourth(!isFourth)} xmlns="http://www.w3.org/2000/svg" width="32" height="32" name="eighth" fill="#0099ff" className="bi bi-star-fill" viewBox="0 0 16 16">
        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
        </svg>}
        <br/>
        <br/>
        <div className="form-floating">
        <textarea className="form-control" placeholder="Share Your Experience (optional)" id="floatingTextarea" onChange={e => setComment(e.target.value)}></textarea>
        <label for="floatingTextarea">Share Your Experience (optional)</label>
        </div>
        <br/>
        <br/>
        {isFirst && isAuthenticated && <button type="button" className="btn btn-primary" onClick={e => handleReviewSubmission(e)}>Submit</button>}
        {!isFirst && !isSecond && !isThird && !isFourth && <button type="button" className="btn btn-primary" disabled>Submit</button>}
        <br/><br/>
        {!isAuthenticated && (
            <div className="alert alert-info" role="alert">
            You Need To Be Logged In To Submit a Review. 
            </div>
          
        )}
        {isShowInvalidMessage && (
            <div className="alert alert-danger" role="alert">
            Invalid characters detected. Please don't use special characters.
            </div>
        )}
        {isShowSuccessMessage && (
            <div className="alert alert-success" role="alert">
            Your Review Has Been Submitted. Thank you.
            </div>
        )}
        {isShowNotAllowedMessage && (
            <div className="alert alert-danger" role="alert">
            You Can No Longer Submit a Review For This Recruiter.
            </div>
        )}
        <br/><br/>
        {profileToDisplay.comments.length !== 0 && profileToDisplay.comments.filter(
            comment => comment !== '\n').map(comm => <h3><span className="badge text-bg-secondary">{comm}</span></h3>)}
        </div>       
        </>
    )
}

export default Profile;