import React from 'react';
import Picture from './images/picture.webp';


const LandingPage = props => {
    return (
        <>
        <br/>
        <br/>
        <div className="container" style={{textAlign: 'center', width: window.innerWidth < 800 ? '85%': '35%'}}>
        
        <form>
            <div className="input-group input-group-lg">
            <input id="searchfield" type="text" className="form-control" placeholder={window.innerWidth < 800 ? 'Name of Recruiter': 'Name of Recruiter or Employer'} onChange={e => props.setRecruiter(e.target.value)} value={props.isRecruiter} required />
            <button id="magnifyingglass" type="submit" onClick={e => props.handleSearchButton(e)} className="btn btn-primary"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
            </svg></button>
            </div>
            {props.showNoResult && (
                <>
                <br/>
                <div className="alert alert-warning" role="alert">
                 This recruiter isn't in the database yet. More recruiters are being added every day. Check back later.
                </div>
                </>
            )}

            {props.isInvalidInputDetected && (
                <>
                <br/>
                <div className="alert alert-warning" role="alert">
                 Please Don't Use Any Special Characters.
                </div>
                </>
            )}
            {props.isLoggedUsername && !props.isEmailConfirmed && (
                <>
                <br/>
                <div className="alert alert-info" role="alert">
                    <strong>Please click on the link we've sent into your inbox.</strong>
                </div>
                </>
            )}
            {props.isLoggedUsername && props.isEmailConfirmed && !props.isShownOnce && (
                <>
                <br/>
                <div id="alert" className="alert alert-success" role="alert">
                <strong>Your Email Has Been Confirmed. Thank you.</strong>
                </div> 
                </>
            )}
        </form>
        </div>
        <div className='container' style={{textAlign: 'center'}}>
        <br/>
        <br/>
        <br/>
        <h1 className='fs-1'>Are You Frustrated When You Spend <u>Hours</u> Crafting Job Applications Only 
            To Receive Automated Emails And No Feedback ?
        <br/>
        <br/>
        </h1>
        <h2 className='fs-2'>Are You a Job Hunter ? Don't Waste Your Precious Time With Recruiters Who Ghost Candidates.</h2>
        <br/>
        <h3 className='fs-3'>recruitDex is Here To Save Candidate's Time. Rate The Quality of Feedback You Have Received And Reward
        Recruiter With a <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg></h3>
        <br/>
        <h3>Did you know ...</h3>
        <br/>
        <div className="container text-center">
            <div className="row align-items-center">
                <div className="col">
                <div className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    
                    <div className="carousel-item active" data-bs-interval="7000">
                    <div className='container-sm'><p className='fs-2'>... each high quality, thoughtful job application may take over an hour to submit ? An hour noone
                    pays and often job hunters don't even get helpful feedback from the recruiter. Copy cats and AI-generated replies don't count.
                    </p></div>
                    </div>
                    <div className="carousel-item" data-bs-interval="7000">
                    <div className='container-sm'><p className='fs-2'>
                        ... feedback is meant to help the candidate to get better for next opportunity. How many helpful feedbacks do you normally get ?
                        Stats say on 100 job applications a candidate gets 1 helpful feedback. (if they're lucky)
                    </p>
                    </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="7000">
                    <div className='container-sm'><p className='fs-2'>
                        ... majority of candidates don't get helpful feedback for their job application ? Making their time and effort spent on job application a complete waste.
                    </p>
                    </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="7000">
                    <div className='container-sm'><p className='fs-2'>... unfilled position may cost companies tens of thousands ($, £, ...) each month but <b>job hunters lose money too</b>.</p>
                    </div>
                    </div>
                </div>
                </div>
                </div>
                {window.innerWidth > 800 && (
                    <>
                    <div className="col">
                    <img src={Picture} alt="frustrated man with laptop" />
                    </div>
                    </>
                )}           
            </div>
            {window.innerWidth < 800 && (
                    <>
                    <br/>
                    <div className="col">
                    <img style={{width: '90%', height: 'auto'}} src={Picture} alt="frustrated man with laptop" />
                    </div>
                    </>
                )}    
            </div>
        <br/>
        <div style={{marginTop: '150px', marginBottom: '200px'}} className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
        <div className="carousel-item active" style={{width: '100%'}} data-bs-interval="8000">
            <div className='container-sm'><p className='fs-2'>Gross Average Weekly Salaries</p>
            <table className="table">
            <thead>
                <tr>
                <th scope="col">Role</th>
                <th scope="col">US</th>
                <th scope="col">UK</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>Head Waitress</td>
                <td>$750</td>
                <td>£570</td>
                </tr>
                <tr>
                <td>Builder</td>
                <td>$920</td>
                <td>£840</td>
                </tr>
                <tr>
                <td>Architect</td>
                <td>$1340</td>
                <td>£860</td>
                </tr>
            </tbody>
            <small>source: Indeed.com & glassdoor.com</small>
            </table>
            </div>
            </div>
            <div className="carousel-item" data-bs-interval="8000">
            <div className='container-sm'><p className='fs-2'>Gross Average Weekly Salaries</p>
            <table className="table">
            <thead>
                <tr>
                <th scope="col">Role</th>
                <th scope="col">US</th>
                <th scope="col">UK</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>Sous Chef</td>
                <td>$1400</td>
                <td>£950</td>
                </tr>
                <tr>
                <td>Electrician</td>
                <td>$960</td>
                <td>£770</td>
                </tr>
                <tr>
                <td>Hotel Receptionist</td>
                <td>$1340</td>
                <td>£520</td>
                </tr>
            </tbody>
            <small>source: Indeed.com & glassdoor.com</small>
            </table>
            
            </div>
            </div>
            <div className="carousel-item" data-bs-interval="8000">
            <div className='container-sm'><p className='fs-2'>Gross Average Weekly Salaries</p>
            <table className="table">
            <thead>
                <tr>
                <th scope="col">Role</th>
                <th scope="col">US</th>
                <th scope="col">UK</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>Quantitative Developer</td>
                <td>$3500</td>
                <td>£1500</td>
                </tr>
                <tr>
                <td>Python Developer</td>
                <td>$2400</td>
                <td>£960</td>
                </tr>
                <tr>
                <td>College Administrator</td>
                <td>$1050</td>
                <td>£340</td>
                </tr>
            </tbody>
            <small>source: Indeed.com & glassdoor.com</small>
            </table>
            
            </div>
            </div>
            <div className="carousel-item" data-bs-interval="8000">
            <div className='container-sm'><p className='fs-2'>Gross Average Weekly Salaries</p>
            <table className="table">
            <thead>
                <tr>
                <th scope="col">Position</th>
                <th scope="col">US</th>
                <th scope="col">UK</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>Lawyer</td>
                <td>$2110</td>
                <td>£1500</td>
                </tr>
                <tr>
                <td>Social Worker</td>
                <td>$1540</td>
                <td>£770</td>
                </tr>
                <tr>
                <td>Registered Nurse</td>
                <td>$1800</td>
                <td>£615</td>
                </tr>
            </tbody>
            <small>source: Indeed.com & glassdoor.com</small>
            </table>
            
            </div>
            </div>
            <div className="carousel-item" data-bs-interval="8000">
            <div className='container-sm'><p className='fs-2'>Gross Average Weekly Salaries</p>
            <table className="table">
            <thead>
                <tr>
                <th scope="col">Position</th>
                <th scope="col">US</th>
                <th scope="col">UK</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>Civil Engineer</td>
                <td>$1100</td>
                <td>£860</td>
                </tr>
                <tr>
                <td>Web Designer</td>
                <td>$2000</td>
                <td>£865</td>
                </tr>
                <tr>
                <td>Paramedic</td>
                <td>$1360</td>
                <td>£670</td>
                </tr>
            </tbody>
            <small>source: Indeed.com & glassdoor.com</small>
            </table>
            </div>
            </div>
            <div className="carousel-item" data-bs-interval="8000">
            <div className='container-sm'><p className='fs-2'>Gross Average Weekly Salaries</p>
            <table className="table">
            <thead>
                <tr>
                <th scope="col">Position</th>
                <th scope="col">US</th>
                <th scope="col">UK</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>Roofer</td>
                <td>$1190</td>
                <td>£770</td>
                </tr>
                <tr>
                <td>Primary School Teacher</td>
                <td>$1200</td>
                <td>£880</td>
                </tr>
                <tr>
                <td>Game Developer</td>
                <td>$1200</td>
                <td>£960</td>
                </tr>
            </tbody>
            <small>source: Indeed.com & glassdoor.com</small>
            </table>
            </div>
            </div>
            </div>
            </div>
            </div>
    </>
    )
}

export default LandingPage;