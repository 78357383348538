import React, { useEffect } from 'react';
import { loadCaptchaEnginge, LoadCanvasTemplate } from 'react-simple-captcha';

const Account = props => {

    useEffect(() => {
        loadCaptchaEnginge(6);
    }, []);

    
    return (
        <>
        <br/>
        <br/>
        <div className="container" style={{width: window.innerWidth < 800 ? '90%': '35%'}}>
        <button style={{borderColor: 'transparent', position: 'absolute', right: window.innerWidth < 800 ? '30px': '100px', top: window.innerWidth < 800 ? '60px': '100px', backgroundColor: 'transparent'}}
         onClick={e => props.handleCrossButton(e)}><svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
        </svg></button>
        <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
        {!props.isRegister && <input type="radio" className="btn-check" onClick={() => props.setRegister(!props.isRegister)} name="btnradio" id="btnradio1" autocomplete="off" checked={true} />}
        {props.isRegister && <input type="radio" className="btn-check" onClick={() => props.setRegister(!props.isRegister)} name="btnradio" id="btnradio1" autocomplete="off" checked={false} />}
        <label className="btn btn-outline-primary" for="btnradio1">Login</label>

        {!props.isRegister && <input type="radio" className="btn-check" onClick={() => props.setRegister(!props.isRegister)} name="btnradio" id="btnradio2" autocomplete="off" checked={false} />}
        {props.isRegister && <input type="radio" className="btn-check" onClick={() => props.setRegister(!props.isRegister)} name="btnradio" id="btnradio2" autocomplete="off" checked={true} />}
        <label class="btn btn-outline-primary" for="btnradio2">Register</label>
        </div>
        <br/>
        <br/>
        <form>
        
        {props.isRegister && (
            <>
            <div className="mb-3">
            <input type="text" placeholder='Username' className="form-control" onChange={e => props.setUsername(e.target.value)} value={props.isUsername} required />
            </div>
            </>
        )}
        <div className="mb-3">
            <input type="email" placeholder='@' className="form-control" onChange={e => props.setEmail(e.target.value)} id="exampleInputEmail1" value={props.isEmail} required />
            {props.isRegister && <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>}
        </div>
        <div className="mb-3">
            <input type="password" placeholder='Password' className="form-control" onChange={e => props.setPassword(e.target.value)} id="exampleInputPassword1" value={props.isPassword} required />
        </div>
                
        <LoadCanvasTemplate />
        <div className="mb-3">
            <input type="text" placeholder='' className="form-control" onChange={e => props.setCaptchaInput(e.target.value)} id="exampleInputPassword1" value={props.isCaptchaInput} required />
        </div>
        
        {props.isRegister && <button type="submit" onClick={e => props.handleSubmit(e)} className="btn btn-primary">Register</button>}
        {!props.isRegister && <button type="submit" onClick={e => props.handleSubmit(e)} className="btn btn-primary">Login</button>}
        <br/><br/>
        {props.isValidationTriggered && (
            <div className="alert alert-warning" role="alert">
            Username Needs To Be Minimum of 5 Characters Long.
            Password Needs To Be Minimum of 8 Characters Long and Include 1 Special Character and 1 Number.
           </div>
        )}
        {props.isNoUserFound && (
            <div className="alert alert-warning" role="alert">
            No User Found. Feel Free To Register.
           </div>
        )}
        {props.isShowUserExists && (
            <div className="alert alert-warning" role="alert">
            User Already Exists. Please Log In.
           </div>
        )}
        {props.showInvalidEmail && (
             <div className="alert alert-warning" role="alert">
             Only the following emails are allowed:<br/><br/>@gmail, @yahoo, @live, @outlook, @mail, @btinternet,
                @protonmail, @proton, @icloud, @googlemail, @murena, @pm
            </div>
        )}
        </form>
        </div>
        </>
    );
}

export default Account;