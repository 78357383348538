import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';


const ConfirmEmail = props => {
    const { code } = useParams();

    useEffect(() => {
        const json = JSON.stringify({code: code});
        axios.post('https://marekintech.pythonanywhere.com/confirm_email', json, { headers: {'Content-Type': 'application/json'}}).then(
            res => {
                if(res.data.status === 'success') {
                    props.setEmailConfirmed(true);
                    
                }
            }
        ).catch(err => console.log(err));
        
    }, []);


    return (
        <p></p>
    )
    
}

export default ConfirmEmail;