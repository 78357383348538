import React from 'react';
import Post1 from './images/post1.png';
import Post2 from './images/post2.png';
import Post3 from './images/post3.png';
import Post4 from './images/post4.png';
import Post5 from './images/post5.png';

const Why = props => {
    return (
        <>
            <div style={{marginTop: '30px'}} className='container'>
            <button style={{borderColor: 'transparent', position: 'absolute', right: window.innerWidth < 800 ? '5px': '100px', top: window.innerWidth < 800 ? '55px': '100px',
                 backgroundColor: 'transparent'}} onClick={e => props.handleCrossButton(e)}><svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
            </svg></button>
                Can you relate to the posts below ?<br/>
                Recruiters and hiring managers are using ATS and AI tools to filter candidates out.
                Candidates who spend valuable time to craft a cover letter, to research, to take part
                in tedious virtual assessments, to fill out all the fields in job application (to fill out information
                that is already stated in the resume).
                If recruiters can use software to save their time and energy why not candidates ? Recruiter's time
                isn't more valuable than candidate's.<br/><br/>
                
                Has a recruiter invested time and energy into his education and training ? Probably yes.
                <br/>
                Has a candidate invested time and energy into his education and training ? Probably yes.
                <br/>
                Does a recruiter have years-worth experience in one field ? Very likely.
                <br/>
                Does a candidate have years-worth experience in one field ? Very likely.
                <br/><br/>
                The goal of recruitDex is to level the field of job searching. To make job search
                less frustrating and to help job seekers channel time and energy where it will
                be appreciated and recognised.
                Why should a candidate invest time and energy sending job application to a recruiter 
                if (s)he has a history of not providing any feedback and sending the candidate copy-cats emails instead ?

                Use recruitDex to rate quality of feedback you received and you will help other job seekers. Others will
                do the same. Help each other save time and energy.
                <br/>
                <br/>
                {window.innerWidth < 800 && (
                    <>
                    <img style={{width: '95%', height: 'auto', padding: '10px'}} src={Post1} alt="first frustrated post" />
                    <img style={{width: '95%', height: 'auto', padding: '10px'}} src={Post2} alt="second frustrated post" />
                    <img style={{width: '95%', height: 'auto', padding: '10px'}} src={Post3} alt="third frustrated post" />
                    <img style={{width: '95%', height: 'auto', padding: '10px'}} src={Post4} alt="fourth frustrated post" />
                    <img style={{width: '95%', height: 'auto', padding: '10px'}} src={Post5} alt="fifth frustrated post" />
                    </>
                )}
                {window.innerWidth > 800 && (
                    <>
                    <img style={{padding: '10px'}} src={Post1} alt="first frustrated post" />
                    <img style={{padding: '10px'}} src={Post2} alt="second frustrated post" />
                    <img style={{padding: '10px'}} src={Post3} alt="third frustrated post" />
                    <img style={{padding: '10px'}} src={Post4} alt="fourth frustrated post" />
                    <img style={{padding: '10px'}} src={Post5} alt="fifth frustrated post" />
                    </>
                )}
                <br/><br/>
            </div>
        </>
    )
}

export default Why;